<template>
    <nav aria-label="Page navigation example">
        <ul class="pagination pagination-lg justify-content-between">
            <li class="page-item disabled">
                <router-link  class="btn default-btn" :to="{ name: 'CourseLesson', params: {id: previous}}"  v-if="previous">Previous</router-link>
                <button class="btn default-btn" href="#" disabled v-else>Previous</button>
            </li>
            <li class="page-item">
                <router-link  class="btn default-btn" :to="{ name: 'CourseLesson', params: {id: next}}"  v-if="next">Next</router-link>
                <router-link  class="btn default-btn" :to="{ name: 'CourseQuizDetails', params: {id: quizId}}"  v-else-if="quizId">Take A Quiz</router-link>
                <router-link  class="btn default-btn" :to="{ name: 'CourseLesson', params: {id: nextLesson.id}}"  v-else-if="nextLesson">NextChapter</router-link>
                <router-link  class="default-btn" :to="{ name: 'CourseCompleted',params: {id:  courseId}}" v-else>Complete Course</router-link>
                <!-- <button class="default-btn" @click="completeCourse()" v-else>Complete Course</button> -->
            </li>
        </ul>
    </nav>
</template>

<script>
import axios from 'axios'
export default {
    props: ['counter','next','previous','quizes','chapterId','courseId'],
    data(){
        return {
            token: localStorage.getItem('user_data'),
            nextPrevious: {},
            duration: this.counter,
            quizId: null,
            nextLesson: {}
        }
    },
    watch: {
        duration: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.duration--
                    }, 1000);
                }
            },
            immediate: true // This ensures the watcher is triggered upon creation
        },
        "$route.params.chapter": {
        handler: function() {
            this.getNextLesson()
            if (this.quizId) {
                this.learnQuiz()
            }
            
        },
        deep: true,
        immediate: true,
    },
    },
    methods:{
        learnQuiz(){
            axios.get('https://apitraining.vipawaworks.com/api/quiz/get_first_quiz_with_questions/' + this.chapterId).then(response => {
                this.quizId = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getNextLesson(){
            axios.post('https://apitraining.vipawaworks.com/api/lesson/get_next_lesson/' + this.chapterId).then(response => {
                this.nextLesson = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        completeCourse(){
            axios.post('https://apitraining.vipawaworks.com/api/course/completeLessons/' + this.courseId).then(response => {
                response.data
                this.$router.push({ name: 'CourseCompleted', params: {id: this.courseId} })
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
    },
    created(){
        this.learnQuiz()
        this.getNextLesson()
    }
    
}
</script>

<style>

</style>