<template>
    <div class="home" v-if="!isLoading">
    <Navbar :user="user" />
    <section class="courses-details-area pt-100 pb-70">
        <div class="container">
            <div class="courses-details-header">
                <div class="row justify-content-center">
                    <div class="col-md-9">
                        <div class="courses-title">
                            <h2>{{ course.title }}</h2>
                            <tabs :options="{ defaultTabHash: 'readTab' }" nav-item-class="nav-item" nav-class="nav nav-tabs justify-content-center" nav-item-link-class="nav-link btn" nav-item-link-active-class="active section-title">
                                <tab id="readTab" name="Read Content">
                                    <div class="shadow-lg p-3 mb-5 bg-body rounded">
                                        <br>
                                        <h3>{{ lesson.name }}</h3>
                                        <div class="text-start">
                                            <span v-html="lesson.content"></span>
                                        </div>
                                    </div>
                                </tab>
                                <tab id="second-tab" name="Watch Video">
                                   <div class="ratio ratio-16x9" v-if="lesson.video">
                                        <iframe src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" title="YouTube video" allowfullscreen></iframe>
                                    </div>
                                    <div v-else class="pt-5">
                                        <h3 class="text-center">No video for this Lesson</h3>
                                    </div>
                                </tab>
                            </tabs>
                        </div>
                        <Nav :counter="lesson.duration" :chapterId="lesson.course_content_id" :next="next" :previous="previous" :quizes="quizes" :courseId="course.id" />
                    </div>
                    <div class="col-md-3">
                        <div class="sticky-top">
                            <div class="shadow-lg p-3 mb-5 bg-body rounded">
                                <h3 class="text-center">Table of Content</h3>
                                <BeforeAccordion :course_id="course.id" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
    <div v-else>
        <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Loading from '@/components/Loading.vue'
import BeforeAccordion from '@/components/chapter/BeforeAccordion.vue'
import Nav from '@/components/chapter/Nav.vue'
export default {
    components: { Navbar,Loading,BeforeAccordion,Nav },
    data(){
        return {
            rating: 4.3,
            isLoading: true,
            token: localStorage.getItem('user_data'),
            errors: {},
            lesson: {},
            course: {},
            user: {},
            course_content: {},
            subscribed: '',
            previousNext: false,
            timerCount: 30,
            next: '',
            previous:''
        }
    },
    watch: {
        "$route.params.chapter": {
        handler: function() {
            this.isLoading =  true
            this.getLesson()
            this.learnLesson()
        },
        deep: true,
        immediate: true,
        },
    },
    methods:{
        getLesson(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/lesson/public_profile/' + id).then(response => {
                this.lesson = response.data.lesson 
                this.next = response.data.next
                this.previous = response.data.previous
                this.quizes = response.data.quiz
                this.course = response.data.course
                document.title = this.lesson.name +' - Training'
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
        learnLesson(){
            let id = this.$route.params.id
            axios.post('https://apitraining.vipawaworks.com/api/lesson/take_lesson/' + id).then(response => {
               this.subscribed = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        countDownDuration(){
            if(this.lesson.duration > 0) {
                setTimeout(() => {
                    this.lesson.duration -= 1
                    this.setTimeout()
                }, 1000)
            }
        },
        getUser(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
                this.user = response.data
            }).catch(errors => {
                if (errors.response.status === 401) {
                localStorage.removeItem('user_data')
                this.$router.push({ name: 'Login', query: { redirect: '/courses/chapters/lesson/'+ id } })
                }
            })
        },
    },
    created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getUser() 
        this.getLesson()
        this.learnLesson()
    },
}
</script>

<style scoped>

</style>