<template>
    <div>
        <Accordion :course_content="course_content"/>
    </div>
</template>

<script>
import axios from 'axios'
import Accordion from './Accordion.vue'
export default {
    components: { Accordion  },
    props: ['course_id'],
    data(){
        return {
            token: localStorage.getItem('user_data'),
            course_content: {},
        }
    },
    methods:{
       getCourseContent(){
            axios.get('https://apitraining.vipawaworks.com/api/course_content/get_contents_with_lessons_quizzes/' + this.course_id).then(response => {
                this.course_content = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
    },
    created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getCourseContent()
    },

}
</script>

<style>

</style>